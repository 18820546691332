import { Box, Button, Chip, IconButton, Pagination, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useRef } from 'react';
import FilterRoot from '../../components/filter/FilterRoot';
import LinkTo from '../../components/LinkTo';
import LoadingWrap from '../../components/LoadingWrap';
import StyledDateRangePicker from '../../components/StyledDateRangePicker';
import EditIcon3 from '../../components/svg/EditIcon3';
import MyTable, { TableHeadContent } from '../../components/table/MyTable';
import color from '../../config/Colors';
import { AuthContext, AuthPermission } from '../../layout/AuthLayout';
import { VehicleList, VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import { TitleWithBackButton } from '../../styles/style';
import { VehicleListPageProps } from '../vehicleList';
import { ZoneList } from '../vehicleList/components/VehicleListItem';
import { useVehicleListParams, WrapVehicleList } from '../vehicleListDetails';
import TableRowsHistoryAction from '../vehicleListDetails/components/TableRowsHistoryAction';
import useVehicleListHistoryLog from '../vehicleListDetails/hooks/useVehicleListHistoryLog';
import { SearchField } from '../vehicleListDetails/StartAdornment';
import { ServiceContext } from '../../providers/ServiceProvider';
import { ServiceId } from '../../models/vehicleList/Service';
import { useParams, useSearchParams } from 'react-router-dom';

export type EventLogAction = {
    onEdit(vehicleList: VehicleList): void;
};

export type ViewEventLogsProps = VehicleListPageProps & {
    vehicleList: VehicleListWithZone;
    action?: EventLogAction;
};

export function EventLogPage(props: ViewEventLogsProps) {
    const { state, setPartialFilter, defaultFilterEventLogs, setPartialActionFilter } = useVehicleListHistoryLog({
        vehicleListId: props.vehicleList.id,
    });
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isGlobal = !props.locationId && !props.zoneId;
    const vehicleList = props.vehicleList;
    const { permission: _permission } = useContext(AuthContext);
    const { getService } = useContext(ServiceContext);
    const readOnly = _permission === AuthPermission.READ_ONLY;

    const params = useParams();

    console.log(`params `, params);

    const [searchParams, setSearchParams] = useSearchParams({});

    const setParam = () => {};

    const addQueryParam = () => {
        const newQueryParamValue = 'your_value';
        const currentSearch = window.location.search;

        const newSearch = new URLSearchParams(currentSearch);
        newSearch.set('yourParamName', newQueryParamValue);

        const newUrl = `${window.location.pathname}?${newSearch.toString()}`;

        window.history.pushState({}, '', newUrl);
    };

    const queryParams = new URLSearchParams(window.location.search);
    const yourParamValue = queryParams.get('yourParamName');

    console.log(yourParamValue);

    return (
        <Box sx={{ px: '12px', pt: isGlobal ? 0 : 3, mb: 10 }}>
            <Stack gap={3}>
                <Stack spacing={1} flex={1}>
                    <Stack direction={'row'} alignItems="center" sx={{ height: 24.69 }}>
                        <TitleWithBackButton variant="h4" ml={0}>
                            Event logs of {vehicleList.name}
                        </TitleWithBackButton>
                        <Chip
                            size="small"
                            label={getService?.(vehicleList.serviceId)?.name}
                            sx={{ borderRadius: '16px !important', mx: 1.5 }}
                        />

                        <Box>
                            {[ServiceId.IPark, ServiceId.InternalPermit].includes(vehicleList.serviceId) && (
                                <Tooltip
                                    title={props.vehicleList.isDefault ? 'Unable to edit default vehicle list' : ''}
                                >
                                    <span>
                                        <LinkTo
                                            isDisable={props.vehicleList.isDefault}
                                            to={`${props.action?.onEdit(props.vehicleList)}`}
                                        >
                                            <IconButton
                                                color="primary"
                                                disabled={readOnly || props.vehicleList.isDefault}
                                            >
                                                <EditIcon3 />
                                            </IconButton>
                                        </LinkTo>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    </Stack>
                    <ZoneList zoneName={vehicleList.zoneName} scopeType={vehicleList.permits?.[0].scopes[0]?.scopeType} totalZone={vehicleList.totalZone}/>
                </Stack>
                <FilterRoot
                    handleResetFilter={() => {
                        if (inputRef.current) inputRef.current.value = '';
                        setPartialActionFilter({ filter: defaultFilterEventLogs });
                    }}
                    rightTitleContent={
                        <Stack direction={'row'} spacing={2}>
                            <Stack>
                                <StyledDateRangePicker
                                    value={{ startDate: state.filter.filter.from, endDate: state.filter.filter.to }}
                                    onChange={(value) => {
                                        setPartialFilter({
                                            filter: {
                                                ...state.filter.filter,
                                                from: value.startDate,
                                                to: value.endDate,
                                            },
                                            page: 1,
                                        });
                                    }}
                                    label="Event logs date"
                                />
                            </Stack>

                            <SearchField
                                inputRef={inputRef}
                                searchTypes={[itemSearchType]}
                                selectType={itemSearchType}
                                onChangeType={() => {}}
                                onChange={_.debounce((e) => {
                                    setPartialFilter({
                                        search: { ...state.filter.search, value: e.target.value },
                                        page: 1,
                                    });
                                }, 500)}
                            />
                        </Stack>
                    }
                />
                <LoadingWrap rows={state.vlHistoryPagination.data} loading={state.isVLHistoryLoading}>
                    <Stack gap={3}>
                        <Typography textAlign={'right'} color={color.success}>
                            Total event logs: {state.vlHistoryPagination.total}
                        </Typography>
                        <MyTable
                            tableHead={ActionTableHeaders}
                            tableRow={<TableRowsHistoryAction historyItems={state.vlHistoryPagination.data} />}
                        />

                        {state.vlHistoryPagination.totalPage > 1 && (
                            <Stack
                                sx={{
                                    '& nav': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                    },
                                }}
                            >
                                <Pagination
                                    count={state.vlHistoryPagination.totalPage}
                                    page={state.vlHistoryPagination.page}
                                    onChange={(e, page) => {
                                        setPartialFilter({ page: page });
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                />{' '}
                            </Stack>
                        )}
                    </Stack>
                </LoadingWrap>
            </Stack>
        </Box>
    );
}

export function WrapEventLogPage() {
    const { path, locationId, zoneId } = useVehicleListParams();
    return (
        <WrapVehicleList>
            {(vehicleList) => (
                <EventLogPage
                    vehicleList={vehicleList}
                    locationId={locationId}
                    zoneId={zoneId}
                    action={{
                        onEdit: () => {
                            return `/${path}/vehicle-list/edit/${vehicleList.id}`;
                        },
                    }}
                />
            )}
        </WrapVehicleList>
    );
}

const itemSearchType = {
    name: 'Search by user',
    id: 'user',
};

const ActionTableHeaders: TableHeadContent[] = [
    {
        label: 'Date/Time',
        alight: 'left',
        style: { width: '250px' },
    },
    {
        label: 'User',
        alight: 'left',
        style: { width: '300px' },
    },
    {
        label: 'Action',
        alight: 'left',
        style: { width: '250px' },
    },
    {
        label: 'Description',
        alight: 'left',
    },
];
