/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { vehicleListController } from '../../../controllers';
import { yupResolver } from '@hookform/resolvers/yup';
import _, { cloneDeep, forEach } from 'lodash';
import { Location, LocationWithZones } from '../../../models/scope/Location';
import useVehicleListGetData from './useVehicleListGetData';
import useVehicleListSearchOption from './useVehicleListSearchOption';
import useVehicleListSelection from './useVehicleListSelection';
import {
    defaultFormData,
    DefaultPermit,
    defaultRecur,
    LOCATION_SCOPE_ALL,
    LOCATION_SCOPE_CUSTOM,
    VehicleListSchema,
    REGIS_TYPE_FIXED_TIME,
    REGIS_TYPE_SPECIFIC_DURATION,
    SCOPE_TYPE_CUSTOM,
    SERVICE_TYPE_INTERNAL_PERMIT,
    SERVICE_TYPE_IPARK,
    ALL_DAY_SECONDS,
    TENANT_SCOPE_ALL,
} from '../constant';
import { PermitType, ValidateToType } from '../../../models/permit/Permit';
import { VehicleList } from '../../../models/vehicleList/VehicleList';
import { Scope, ScopeType } from '../../../models/permit/Scope';
import { RecurringType } from '../../../models/permit/PermitRecurring';
import { addDays, addMonths, addSeconds, getDate, getMonth, isValid, startOfDay } from 'date-fns';
import { convertHour } from '../../../helpers/ConvertHour';
import { CompareDateWithUTC, ConvertToGMT0Time } from '../../../helpers';
import moment from 'moment';
import { pushError, pushSuccess } from '../../../components/toast';
import { COMPANY_SCOPE_ALL, COMPANY_SCOPE_CUSTOM } from '../components/permitScope/PermitScopeItem';
import { genWeekDate, getTimeFromRegex, isCompany, isPortfolio } from '../helper';
import useVehicleListEdit from './useVehicleListEdit';
import useVehicleListLocation from './useVehicleListLocation';
import { FormData, UnionScope } from '../components/model/UpsertVehicleList';
import { ParentProps } from '..';
import { Portfolio } from '../../../models/scope/Portfolio';

export interface ScopeState {
    listCompanySelect: UnionScope[];
    isScopeValid: boolean;
    isAllScopeValid: boolean;
    isSubmitting: boolean;
}

export default function useVehicleListUpsert(props: ParentProps) {
    const [scopeState, setScopeState] = useState<ScopeState>({
        listCompanySelect: [],
        isScopeValid: true,
        isAllScopeValid: true,
        isSubmitting: false,
    });
    const setPartialState = (partial: Partial<ScopeState>) => setScopeState((prev) => ({ ...prev, ...partial }));
    const { zoneId, locationId, vehicleListId } = props;
    const isEdit = vehicleListId ? true : false;
    const isGlobal = !locationId && !zoneId;

    const { listCompanySelect } = scopeState;

    //get data from api
    const { dataState, setPartialDataState, loading, defaultDataBE } = useVehicleListGetData(props);
    const { companies, locations, portfolios } = dataState;

    //search input
    const { searchOption, setPartialSearchOption } = useVehicleListSearchOption();

    //get and set company, location when select from user selection
    const vehicleListSelection = useVehicleListSelection({
        isAllScopeValid: scopeState.isAllScopeValid,
        setPartialState,
    });
    const { selectionState, setPartialSelectionState } = vehicleListSelection;
    const { companySelects } = selectionState;

    const methods = useForm<FormData>({
        defaultValues: defaultFormData,
        mode: 'all',
        resolver: yupResolver(VehicleListSchema),
    });

    const { isAllowTenant } = methods.watch();

    //get and set Edit data
    const editVehicleListData = useVehicleListEdit({
        parentProps: props,
        methods,
        setPartialState,
        setPartialSelectionState,
        setPartialDataState,
        companies,
        locations,
        companySelects,
        listCompanySelect,
        dataState,
        loading,
        isEdit,
        portfolios,
    });
    const { vehicleListEdit, permit } = editVehicleListData;

    const oldPermit = { ...permit };

    const { locationLoading } = useVehicleListLocation({
        parentProps: props,
        setPartialState,
        setPartialSelectionState,
        setPartialDataState,
        methods,
        isEdit,
    });

    const formSubmitHandler: SubmitHandler<FormData> = (data: FormData) => {
        handleCreateVehicleList(data);
    };

    // console.log('isValid ', methods.formState.isValid);
    // console.log('watch ', methods.watch());
    // console.log('errors ', methods.formState.errors);

    const handleAddScope = (searchType: string, companyId: number, location?: Location) => {
        const newCompany = companies.find((c) => c.id === companyId)!;
        const newList = [...listCompanySelect];
        if (newCompany) newList.push(newCompany);

        const cloneCompanySelects = _.cloneDeep(companySelects);
        if (searchType === 'company') {
            cloneCompanySelects.push({ ...newCompany, locations: [], companyScope: COMPANY_SCOPE_ALL });
        } else {
            const locationItem: LocationWithZones = {
                ...location!,
                zones: [],
                locationScope: LOCATION_SCOPE_ALL,
                shops: [],
                tenantScope: TENANT_SCOPE_ALL,
            };
            //check is company exists
            const index = cloneCompanySelects.findIndex((c) => {
                if (isCompany(c)) return c.id === location!.companyId;
            });
            //when company is exists
            if (index >= 0) {
                const companyExists = _.cloneDeep(cloneCompanySelects[index]);
                if (isCompany(companyExists)) {
                    companyExists.locations!.push(locationItem);
                    cloneCompanySelects[index] = { ...companyExists, companyScope: COMPANY_SCOPE_CUSTOM };
                }
            } else {
                cloneCompanySelects.push({
                    ...newCompany,
                    companyScope: COMPANY_SCOPE_CUSTOM,
                    locations: [locationItem],
                });
            }
        }
        setPartialSelectionState({
            companySelects: cloneCompanySelects,
        });
        //remove all location have idCompany
        const newLocations = _.cloneDeep(locations).filter((item) => {
            if (location) return item.id !== location.id;
            else return true;
        });

        //remove company from select
        const newCompanies = _.cloneDeep(companies).filter((item) => {
            if (newCompany) return item.id !== newCompany.id;
            else return true;
        });
        setPartialDataState({ locations: newLocations, companies: newCompanies });

        //set valid Upsert
        setPartialState({ listCompanySelect: newList, isScopeValid: true });
        setPartialSearchOption({ searchCompany: null, searchLocation: null });
    };

    const handleRemoveCompany = (union: UnionScope) => {
        const newCompanySelects = companySelects.filter((item) => isPortfolio(item) || item.id !== union.id);
        setPartialSelectionState({ companySelects: newCompanySelects });

        if (isCompany(union)) {
            const newCompanies = [...companies, union];
            const newLocations = locations.filter((l) => l.companyId !== union.id);

            union.locations!.forEach((item) => newLocations.push(item));
            newLocations.sort((x, y) => x.name.localeCompare(y.name));
            setPartialDataState({ companies: newCompanies, locations: newLocations });

            const newListCompanySelects = listCompanySelect.filter((item) => item.id !== union.id || isPortfolio(item));
            setPartialState({ listCompanySelect: newListCompanySelects, isScopeValid: newCompanySelects.length > 0 });
        }
    };

    const handleAddPortfolio = (portfolio: Portfolio) => {
        const newPortfolio = portfolios.find((p) => p.id === portfolio.id);
        if (!newPortfolio) return;

        const newList = [...listCompanySelect, newPortfolio];
        const newPortfolios = portfolios.filter((p) => p.id !== portfolio.id);
        const cloneCompanySelects = [...companySelects, newPortfolio];

        setPartialDataState({ portfolios: newPortfolios });
        setPartialSelectionState({ companySelects: cloneCompanySelects });
        setPartialState({ listCompanySelect: newList, isScopeValid: true });
        setPartialSearchOption({ searchCompany: null, searchLocation: null, searchPortfolio: null });
    };

    const handleRemovePortfolio = (portfolio: Portfolio) => {
        //remove from selected
        const newList = _.cloneDeep(listCompanySelect).filter((item) => {
            if (isPortfolio(portfolio)) return item.id !== portfolio.id;
            else return true;
        });
        //remove full item selected
        const cloneCompanySelects = _.cloneDeep(companySelects).filter((item) => {
            if (isPortfolio(portfolio)) return item.id !== portfolio.id;
            else return true;
        });
        setPartialSelectionState({
            companySelects: cloneCompanySelects,
        });

        //add to list portfolios
        const currentPortfolios = _.cloneDeep(portfolios);
        currentPortfolios.push(portfolio);
        setPartialDataState({ portfolios: currentPortfolios });
        setPartialState({ listCompanySelect: newList, isScopeValid: cloneCompanySelects.length === 0 ? false : true });
    };

    const handleCreateVehicleList = (data: FormData, isGoToAdd?: boolean) => {
        const {
            iParkData,
            internalPermitData: {
                fromHour,
                validateFrom,
                toHour,
                validateTo,
                weekRecurringDays,
                endAfterValue,
                endStatus,
                recurringEvery,
                recurringType,
                isCheckedAllDay,
            },
            nameVehicleList,
            scopeType,
            serviceType,
            comment,
            isAllowTenant,
        } = data;
        const vehicleList: VehicleList = {
            id: isEdit ? vehicleListEdit.id : 0,
            serviceId: serviceType,
            name: nameVehicleList,
            status: isEdit ? vehicleListEdit.status : 0,
            permits: [],
            comment: comment || '',
            isAllowTenant: serviceType === SERVICE_TYPE_IPARK ? isAllowTenant : true,
        };

        const permit = DefaultPermit;
        permit.id = isEdit ? vehicleListEdit.permits![0].id : 0;
        permit.vehicleListId = isEdit ? vehicleListEdit.id : 0;

        permit.permitType = (() => {
            switch (serviceType) {
                //iPark
                case SERVICE_TYPE_IPARK:
                    if (iParkData.regisType === REGIS_TYPE_FIXED_TIME) return PermitType.duration;
                    else return PermitType.specific;
                //internal permit
                case SERVICE_TYPE_INTERNAL_PERMIT:
                    return PermitType.schedule;
                default:
                    return PermitType.schedule;
            }
        })();

        permit.duration =
            iParkData.regisType === REGIS_TYPE_FIXED_TIME && serviceType === SERVICE_TYPE_IPARK
                ? getTimeFromRegex(iParkData.fixedTime)
                : 0;
        permit.maxDuration =
            iParkData.regisType === REGIS_TYPE_SPECIFIC_DURATION && serviceType === SERVICE_TYPE_IPARK
                ? iParkData.specificTime * ALL_DAY_SECONDS
                : 0;

        //if exists zone id set scope type to zone
        if (props.zoneId) {
            permit.scopes = [{ id: 0, scopeType: ScopeType.zone, scopeId: Number(props.zoneId) }];
        } else {
            permit.scopes = getPermitScopes(companySelects, scopeType, defaultDataBE.locations);
        }
        //set data when service is internal permit
        if (serviceType === SERVICE_TYPE_INTERNAL_PERMIT) {
            permit.validateFrom = ConvertToGMT0Time(startOfDay(validateFrom!));
            permit.validateToType = endStatus as ValidateToType;
            permit.validateToAfterRecurringTime = endAfterValue;
            permit.permitRecurring = isEdit ? oldPermit.permitRecurring! : [defaultRecur];
            permit.permitRecurring[0].fromHour = convertHour.getSecondFromStartOfDay(fromHour!);
            permit.permitRecurring[0].toHour = isCheckedAllDay
                ? ALL_DAY_SECONDS
                : convertHour.getSecondFromStartOfDay(toHour!);
            permit.permitRecurring[0].id = isEdit ? oldPermit.permitRecurring![0].id : 0;
            permit.permitRecurring[0].recurringType = recurringType;

            const validToSecond = isCheckedAllDay
                ? ALL_DAY_SECONDS - 1
                : convertHour.getSecondFromStartOfDay(toHour!) ?? 0;

            const convertGmt0ValidateFrom = ConvertToGMT0Time(startOfDay(validateFrom!));

            switch (recurringType) {
                case Number(RecurringType.once):
                    permit.permitRecurring[0].recurringEvery = 1;
                    permit.validateTo = convertValidateToWithToHour(new Date(validateFrom!), validToSecond);
                    if (!isEdit) {
                        permit.permitRecurring[0].permitRecurringDays = [
                            {
                                id: 0,
                                firstExecuteAt: convertGmt0ValidateFrom,
                                nextExecuteAt: convertGmt0ValidateFrom,
                            },
                        ];
                    }
                    break;
                case Number(RecurringType.day):
                    permit.permitRecurring[0].recurringEvery = recurringEvery;
                    if (!isEdit) {
                        permit.permitRecurring[0].permitRecurringDays = [
                            {
                                id: 0,
                                firstExecuteAt: convertGmt0ValidateFrom,
                                nextExecuteAt: convertGmt0ValidateFrom,
                            },
                        ];
                    }
                    if (endStatus === ValidateToType.absoluteAt) {
                        permit.validateTo = convertValidateToWithToHour(new Date(validateTo!), validToSecond);
                    }
                    if (endStatus === ValidateToType.recurringTime) {
                        const endDate = addDays(
                            startOfDay(new Date(validateFrom!)),
                            recurringEvery! * (endAfterValue! - 1)
                        );
                        permit.validateTo = convertValidateToWithToHour(endDate, validToSecond);
                    }
                    if (endStatus === ValidateToType.infinite) permit.validateTo = undefined;

                    break;
                case Number(RecurringType.week):
                    permit.permitRecurring[0].recurringEvery = recurringEvery;
                    const newArr = weekRecurringDays.map((item) => (item === 0 ? 7 : item)).sort((a, b) => a - b);
                    const dateArr = genWeekDate(validateFrom!).filter((date) =>
                        newArr.includes(moment(date).isoWeekday())
                    );
                    const endOfWeekFromStartDate = moment(validateFrom!).endOf('isoWeek').startOf('day').toDate();

                    if (!isEdit) {
                        permit.permitRecurring![0].permitRecurringDays = [];
                        dateArr.map((item) => {
                            const date = ConvertToGMT0Time(
                                getDateWeekRecur(startOfDay(item), endOfWeekFromStartDate, recurringEvery)
                            );
                            permit.permitRecurring![0].permitRecurringDays.push({
                                id: 0,
                                firstExecuteAt: date,
                                nextExecuteAt: date,
                            });
                        });
                    }

                    if (endStatus === ValidateToType.absoluteAt) {
                        permit.validateTo = convertValidateToWithToHour(new Date(validateTo!), validToSecond);
                    }
                    if (endStatus === ValidateToType.recurringTime) {
                        const validTo = addDays(endOfWeekFromStartDate, recurringEvery! * 7 * (endAfterValue! - 1));
                        permit.validateTo = convertValidateToWithToHour(validTo, validToSecond);
                    }
                    if (endStatus === ValidateToType.infinite) permit.validateTo = undefined;

                    break;
                case Number(RecurringType.month):
                    permit.permitRecurring[0].recurringEvery = recurringEvery;
                    const firstExecuteAt = calculateFirstDate(validateFrom!, recurringEvery);

                    //set first recur day
                    if (!isEdit) {
                        permit.permitRecurring[0].permitRecurringDays = [
                            {
                                id: 0,
                                firstExecuteAt: ConvertToGMT0Time(startOfDay(firstExecuteAt)),
                                nextExecuteAt: ConvertToGMT0Time(startOfDay(firstExecuteAt)),
                            },
                        ];
                    }
                    if (endStatus === ValidateToType.absoluteAt) {
                        permit.validateTo = convertValidateToWithToHour(new Date(validateTo!), validToSecond);
                    }
                    if (endStatus === ValidateToType.recurringTime) {
                        const validTo = addMonths(firstExecuteAt, endAfterValue! - 1);
                        permit.validateTo = convertValidateToWithToHour(validTo, validToSecond);
                    }
                    if (endStatus === ValidateToType.infinite) permit.validateTo = undefined;

                    break;
                case Number(RecurringType.indefinite):
                    permit.permitRecurring[0].fromHour = convertHour.getSecondFromStartOfDay(startOfDay(new Date()));
                    permit.permitRecurring[0].toHour = ALL_DAY_SECONDS;
                    //set end date
                    permit.validateTo = undefined;
                    permit.permitRecurring[0].recurringEvery = 1;
                    //push recur days
                    if (!isEdit) {
                        permit.permitRecurring[0].permitRecurringDays = [
                            {
                                id: 0,
                                firstExecuteAt: convertGmt0ValidateFrom,
                                nextExecuteAt: convertGmt0ValidateFrom,
                            },
                        ];
                    }
                    break;
                default:
                    break;
            }
        } else {
            permit.permitRecurring = [];
            permit.validateFrom = ConvertToGMT0Time(startOfDay(iParkData.validateFrom!));
            permit.validateToType = iParkData.endStatus;

            if (iParkData.endStatus === ValidateToType.absoluteAt) {
                permit.validateTo = ConvertToGMT0Time(startOfDay(iParkData.validateTo!));
            } else {
                permit.validateTo = undefined;
            }
        }

        //set all recur days to 0
        permit.permitRecurring[0]?.permitRecurringDays.forEach((item) => (item.id = 0));

        vehicleList.permits!.push(permit);
        handleUpsertVehicleList(vehicleList, isGoToAdd);
    };

    const calculateFirstDate = (validateFrom: Date, recurringEvery: number) => {
        let defaultDay = new Date();
        for (let index = getMonth(validateFrom!); index < 13; index++) {
            const date = moment({
                year: validateFrom!.getFullYear(),
                month: index,
                day: recurringEvery,
            }).toDate();
            if (isValid(date)) {
                defaultDay = date;
                break;
            } else continue;
        }
        if (recurringEvery < getDate(validateFrom!)) {
            return addMonths(defaultDay, 1);
        }

        return defaultDay;
    };

    const getDateWeekRecur = (date: Date, endDateOfWeek: Date, recurEvery: number) => {
        const momentDate = moment(date);
        const momentEndDateOfWeek = moment(endDateOfWeek);

        if (momentDate.isAfter(momentEndDateOfWeek)) {
            if (recurEvery === 1) {
                return date;
            }

            return momentDate.add((recurEvery - 1) * 7, 'day').toDate();
        }

        return date;
    };

    const handleUpsertVehicleList = async (vehicleList: VehicleList, isGoToAdd?: boolean) => {
        setPartialState({ isSubmitting: true });
        await vehicleListController
            .upSert(vehicleList)
            .then((res) => {
                setPartialDataState({ companies: companies, locations: locations });

                if (isEdit) pushSuccess('Edit vehicle list success!');
                else pushSuccess('Add vehicle list success!');

                if (isGoToAdd) return props.action.onSaveAndAddPermit?.(res);
                if (!isEdit) return props.action.onSave?.();
            })
            .catch((err) => {
                pushError(err?.response.data);
            })
            .then(() => {
                setPartialState({ isSubmitting: false });
            });
    };

    const getPermitScopes = (scopeSelects: UnionScope[], scopeType: number, allLocation: LocationWithZones[]) => {
        const scopes: Scope[] = [];
        switch (scopeType) {
            case SCOPE_TYPE_CUSTOM:
                scopeSelects.map((union) => {
                    if (isPortfolio(union)) {
                        scopes.push({ id: 0, scopeType: ScopeType.portfolio, scopeId: union.id });
                    } else {
                        if (union.companyScope === COMPANY_SCOPE_CUSTOM) {
                            union.locations?.map((location) => {
                                const tenants = location.shops;
                                //setZone scope
                                if (location.locationScope === LOCATION_SCOPE_CUSTOM) {
                                    location.zones!.forEach((zone) => {
                                        scopes.push({ id: 0, scopeType: ScopeType.zone, scopeId: zone.id });
                                    });
                                }
                                //setTenant when location scope is custom
                                if (location.tenantScope === LOCATION_SCOPE_CUSTOM && !isAllowTenant) {
                                    tenants?.forEach((tenant) => {
                                        if (
                                            !scopes.some(
                                                (s) => s.scopeId === tenant.clientId && s.scopeType === ScopeType.tenant
                                            )
                                        ) {
                                            scopes.push({
                                                id: 0,
                                                scopeType: ScopeType.tenant,
                                                scopeId: tenant.clientId,
                                            });
                                        }
                                    });
                                }
                                //setTenant when location scope is all and tenant is custom
                                if (location.locationScope === LOCATION_SCOPE_ALL) {
                                    scopes.push({ id: 0, scopeType: ScopeType.location, scopeId: location.id });
                                }
                            });
                        } else {
                            scopes.push({ id: 0, scopeType: ScopeType.company, scopeId: union.id });
                        }
                    }
                });

                break;
            //global
            default:
                scopes.push({ id: 0, scopeType: ScopeType.global, scopeId: null });
                break;
        }
        return scopes;
    };

    const handleChangeValidateFromWhenEdit = () => {
        if (isEdit && CompareDateWithUTC(permit.validateFrom!, methods.watch().internalPermitData.validateFrom!)) {
            methods.setValue('internalPermitData.validateFrom', null, {
                shouldTouch: true,
                shouldValidate: true,
            });
        }
    };

    return {
        scopeState,
        setPartialState,
        companies,
        listCompanySelect,
        methods,
        formSubmitHandler,
        handleAddScope,
        handleRemoveCompany,
        selectionState,
        setPartialSelectionState,
        handleCreateVehicleList,
        dataState,
        setPartialDataState,
        searchOption,
        setPartialSearchOption,
        isGlobal,
        isEdit,
        loading,
        locationLoading,
        defaultDataBE,
        handleAddPortfolio,
        handleRemovePortfolio,
        editVehicleListData,
        vehicleListSelection,
        handleChangeValidateFromWhenEdit,
    };
}

const convertValidateToWithToHour = (date: Date, duration: number) => {
    return ConvertToGMT0Time(addSeconds(startOfDay(date), duration ?? 0));
};

export const VehicleListUpsertContext = React.createContext<ReturnType<typeof useVehicleListUpsert>>({} as any);
